import React from "react";
import { ClientSection, Row, SingleClient } from "./Styled";
import { Fade } from "react-reveal";

const Wergo = () => {

  return (
    <SingleClient>
      <h1>Wergo</h1>
      <p>MOBILE</p>

      <Row>
        <Fade>
          <ClientSection>
            <p className="desc">
              We all like to get around with people when out going partying. Wergo is the perfect place to find the places and connect with people.
            </p>
            <p className="desc">
              Find nearest events and celebrations to book tickets and get discounts with single app.
            </p>
          </ClientSection>
        </Fade>
        <ClientSection>
          <div className="client-image">
            <img src="/images/projects/wergo/wergo-single.png" />
          </div>
        </ClientSection>
      </Row>
      <Row>
        <ClientSection>
          <div className="">
            <img src="/images/projects/wergo/challenges.png" />
          </div>
        </ClientSection>
        <ClientSection>
          <div className="desc-highlight">
            <h1>Challenges</h1>
            <p>
              Wergo is a unique platform when it comes to crowd handling. The complex match and messaging between promoters need to handle more elements such as event performance and ticketing.
            </p>
            <p>
              There are location based check in which allow users to go into club by just having the phone.
            </p>
            <p>
              The QR based ticketing is also a key feature that need to work realtime seamlessly
            </p>
          </div>
        </ClientSection>
      </Row>
      <Row>
        <ClientSection>
          <div className="desc-highlight">
            <h1 style={{ marginLeft: '84px', }}>Approach</h1>
            <p>
              1. Location based events and clubs using geo coordinates and real time updates
            </p>
            <p>
              2. Payments integration with stripe.
            </p>
            <p>
              3. Realtime ticketing with frameworks such as Graphql.
            </p>
            <p>
              4. Instant phonebook syncing using in-memory caching
            </p>
          </div>
        </ClientSection>
      </Row>
    </SingleClient>
  )
}

export default Wergo;